import React, { useContext, useEffect, useMemo, useState } from 'react'
import { FieldMappingRow } from './FieldMappingRow/FieldMappingRow'
import { SelectValueMulti } from '@components/Form/MultiCreatableCustom'
import Fuse from 'fuse.js'
import { useField, useFormikContext } from 'formik'
import { MappedField, BridgeFormValues, MappedFieldType } from 'Nbee'
import { ApiBridgeFieldsList, ApiUserModuleItem } from 'BackendApi'
import { PanelPopupContext } from '@components/Panel'
import { FieldMappingEmptyState } from '@features/nbee/FieldsMappingForm/FieldMappingEmptyState'
import { getSortedFields, sortByFieldLabel } from './sortingUtils'
import { PopupContentNoSelectOptions } from '@features/nbee/FieldsMappingForm/popupContent/PopupContentNoSelectOptions'
import { isFieldMapped } from '@features/nbee/FieldsMappingForm/utils'
import {
  closeAlertMessage,
  sendAlertMessage,
} from '@app/store/actions/ApplicationConfigurationActions'
import { useAppDispatch } from '@app/store/hooks'
import { useTranslation } from 'react-i18next'
import { useGetFormulasSchema } from '@app/api/getFormulasSchema'
import { Button } from '@components/Basic/ButtonNbe'
import { useHistory } from 'react-router-dom'
import { useUpdateBridgeToPricing } from '@features/nbee/utils'

interface Props {
  bridgeId: number
  filterText: string
  showAllFields: boolean
  onResetFilterRequest: () => void
  allBridgeFields?: ApiBridgeFieldsList
  formulaUserModule?: ApiUserModuleItem | undefined
  totalFieldsMapped: number
  maxFields?: number
  showWelcomeMessage?: boolean
  sourceLogoUri?: string
}

export const FormInner: React.FC<Props> = ({
  filterText,
  showAllFields,
  onResetFilterRequest,
  allBridgeFields,
  bridgeId,
  formulaUserModule,
  totalFieldsMapped,
  maxFields,
  showWelcomeMessage,
  sourceLogoUri,
}) => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const { t } = useTranslation()
  const panelContext = useContext(PanelPopupContext)
  const [field, meta, helpers] = useField<MappedField[]>('fieldsMapping')

  const { data: formulasSchemaData } = useGetFormulasSchema(`${bridgeId}`)
  const [redirectToPricing, setRedirectToPricing] = useState(false)

  const updateBridgeToPricing = useUpdateBridgeToPricing(bridgeId)

  const foundSomeAutoMapped = field.value.some(
    (f) => isFieldMapped(f) && f.mappingType === 'auto'
  )

  const { isValid, setFieldValue } = useFormikContext<BridgeFormValues>()

  const unmapAllFields = () => {
    const updatedArray = field.value.map((item) => {
      return { ...item, sourceFieldId: undefined, mapping: [] }
    })
    helpers.setValue(updatedArray)
  }

  const handleRedirectToPricing = (redirect: boolean) => {
    setRedirectToPricing(redirect)
  }

  const handleCloseAlert = () => {
    setRedirectToPricing(false)
    dispatch(closeAlertMessage())
  }

  useEffect(() => {
    if (redirectToPricing) {
      dispatch(
        sendAlertMessage({
          isDismissable: true,
          onClose: handleCloseAlert,
          message: t('nbee.fieldsMapping.fieldMappingAlertFormulaTitle'),
          useTranslation: true,
          buttons: (
            <Button
              onClick={() => {
                setRedirectToPricing(false)
                updateBridgeToPricing()
              }}
              $variant={'primary'}
              $size={'standard'}
            >
              {t('nbee.checkCompatibility.updatePlanCta')}
            </Button>
          ),
        })
      )
    } else {
      setRedirectToPricing(false)
      dispatch(closeAlertMessage())
    }
  }, [redirectToPricing])

  useEffect(() => {
    if (maxFields !== undefined && totalFieldsMapped > maxFields) {
      dispatch(
        sendAlertMessage({
          isDismissable: false,
          message: t('nbee.fieldsMapping.fieldMappingAlertTitle'),
          useTranslation: true,
          transValue: maxFields,
          buttons: (
            <div style={{ display: 'flex', gap: '3rem' }}>
              <Button
                onClick={() => {
                  updateBridgeToPricing()
                }}
                $variant={'primary'}
                $size={'small'}
              >
                {t('nbee.checkCompatibility.updatePlanCta')}
              </Button>
              <Button
                onClick={() => unmapAllFields()}
                $variant={'action'}
                $size={'small'}
              >
                Unmap All Fields
              </Button>
            </div>
          ),
        })
      )
    } else if (maxFields !== undefined && totalFieldsMapped <= maxFields) {
      dispatch(closeAlertMessage())
    }
  }, [totalFieldsMapped])

  // this is to scroll to the first error found on the page
  // this will be possibile since InputFeedback component has a `data-error` attribute
  /* useEffect(() => {
    const fieldWithError = document.querySelector("[data-error='true']")
    if (fieldWithError) {
      fieldWithError.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      })
    }
  }, [isValid]) */

  const formFields = field.value
  const sourceFields =
    allBridgeFields?.source.sort((a, b) => a.label.localeCompare(b.label)) || []

  const selectOptions: SelectValueMulti[] = useMemo(() => {
    const sourceFieldOptions = sourceFields.map((field) => ({
      value: field.id,
      label: field.label,
      fieldType: 'source' as MappedFieldType,
      isFormula: false,
    }))
    return [...sourceFieldOptions]
  }, [sourceFields, meta.touched])

  // Since we also need to perform search against selected value, we can compute it
  // and prepare a computedMappedString which contains the "label" of the selected source field
  // or the text manually added
  const formFieldsWithComputedMappedValues = formFields
    .map((field) => {
      // Attempt to find the first mapping with a sourceFieldId and use its label, or fall back to destinationText if not found
      const firstMappingWithSource = field.mapping.find((m) => m.sourceFieldId)
      const sourceFieldLabel = firstMappingWithSource
        ? sourceFields.find(
            (sf) => sf.id === firstMappingWithSource.sourceFieldId
          )?.label
        : ''

      return {
        ...field,
        computedMappedString:
          sourceFieldLabel || field.mapping.find((m) => m.text)?.text || '',
      }
    })
    .sort(sortByFieldLabel)

  const fuzzySearch = new Fuse(formFieldsWithComputedMappedValues, {
    isCaseSensitive: false,
    shouldSort: true,
    findAllMatches: true,
    includeMatches: true,
    includeScore: true,
    threshold: 0.25, // 0 for exact match of letter and location, 1 to match everything
    distance: 500,
    keys: ['destinationFieldLabel', 'computedMappedString'],
  })

  const filteredDestinationFieldsByText = filterText
    ? fuzzySearch.search(filterText).map(({ item }) => item)
    : formFields

  const filteredDestinationFieldsByState = showAllFields
    ? filteredDestinationFieldsByText
    : filteredDestinationFieldsByText.filter((mappedField) =>
        mappedField.mapping.some(
          (mappingItem) =>
            mappingItem.text || mappingItem.sourceFieldId || mappingItem.formula
        )
      )

  // we memoize the sorting so we won't loose order while mapping by moving fields up and down
  const fieldsToRender = useMemo(
    () => getSortedFields(filteredDestinationFieldsByState),
    [filterText, showAllFields, formFields.length]
  )

  const noSelectOptions = selectOptions.length < 1

  useEffect(() => {
    if (noSelectOptions) {
      if (panelContext && panelContext.sendPopup) {
        panelContext.sendPopup({
          content: <PopupContentNoSelectOptions />,
          dismissable: true,
        })
      }
    }
  }, [noSelectOptions])

  useEffect(() => {
    // Extract source fields used directly and in formulas
    const sourceFieldsUsedDirectly = formFields
      .filter((f) => f.mapping.some((m) => m.fieldType === 'source'))
      .flatMap((f) =>
        f.mapping
          .filter((m) => m.fieldType === 'source')
          .map((m) => m.sourceFieldId)
      )

    const sourceFieldsUsedInFormulas = formFields
      .flatMap((f) => f.mapping)
      .filter((m) => m.fieldType === 'formula' && m.formula)
      .flatMap((m) =>
        m.formula!.params.filter((p) => p.type === 'field').map((p) => p.values)
      )

    // Combine all source fields used
    const allSourceFieldsUsed = [
      ...sourceFieldsUsedDirectly,
      ...sourceFieldsUsedInFormulas,
    ].filter(Boolean) as string[]

    // Determine which source fields are no longer available
    const allAvailableOptions = selectOptions.map((o) => o.value)
    const usedSourceFieldsNotAvailable = allSourceFieldsUsed.filter(
      (usedField) => !allAvailableOptions.includes(usedField)
    )

    // Clean up Formik state for mappings using unavailable source fields
    const cleanedFormFieldValues = formFields.map((field) => {
      // Skip cleaning for fields without any mapping
      if (field.mapping.length === 0) {
        return field
      }

      return {
        ...field,

        mapping: field.mapping
          .map((mapping) => {
            // We return a modified mapping or the original mapping
            // This is a placeholder logic to illustrate; adjust according to your actual logic

            return mapping // Return the original mapping if no modification is needed
          })
          .filter(
            (mapping) =>
              mapping.sourceFieldId || mapping.formula || mapping.text
          ), // Remove empty/default mappings that might have been inadvertently added
      }
    })

    helpers.setValue(cleanedFormFieldValues)

    // Notify user about unavailable source fields
    if (usedSourceFieldsNotAvailable.length > 0) {
      dispatch(
        sendAlertMessage({
          isDismissable: true,
          message: t('nbee.fieldsMapping.missingMappedFields', {
            listOfNotFoundIds: usedSourceFieldsNotAvailable.join(', '),
          }),
        })
      )
    }
  }, [selectOptions])

  return allBridgeFields ? (
    <div style={{ paddingBottom: showWelcomeMessage ? '0rem' : '2rem' }}>
      {fieldsToRender.length ? (
        fieldsToRender.map((field) => {
          // we pass the index of formFields and not the one of the filtered array
          // or the entire formik logic inside the component (get value, error and touched state)
          // will not work because will refer to different index
          const index = formFields.findIndex(
            ({ destinationFieldId }) =>
              field.destinationFieldId === destinationFieldId
          )

          if (index < 0) {
            // prevent passing negative index in case a field does not exists anymore (after field refresh)
            return null
          }

          return (
            <FieldMappingRow
              index={index}
              key={field.destinationFieldId}
              selectOptions={selectOptions}
              formulaUserModule={formulaUserModule}
              showWelcomeMessage={showWelcomeMessage}
              formulaSchema={formulasSchemaData?.data}
              sourceLogoUri={sourceLogoUri}
              onRedirectToPricing={handleRedirectToPricing}
            />
          )
        })
      ) : !showWelcomeMessage ? (
        <FieldMappingEmptyState
          onResetFilterRequest={onResetFilterRequest}
          isFiltered={!!formFields.length}
        />
      ) : null}
    </div>
  ) : null
}
